import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const ServiceDesignHP1 = props => (
  <Layout>
    <Helmet>
      <title>Service Design - Hive Part 1</title>
      <meta name="Service Design - Hive Part 1" content="Service Design" />
    </Helmet>

    <div id="main" className="alt">
      <section id="work-banner">
        <div>
          <span className="image main">
            <Img fluid={props.data.hp1cover.childImageSharp.fluid} alt="" />
          </span>
        </div>
      </section>

      <div className="project-wrapper">
        <div className="project-content">
          <header>
            <span className="tag">SERVICE DESIGN</span>
            <span className="tag">USER EXPERIENCE</span>
            <span className="tag">UX STRATEGY</span>
            <span className="tag">BUSINESS DESIGN</span>
            <h1>hive (part 1) - service design.</h1>
            <h3>
              At Hive, food is special because it is made by amazing people.
              Their love, their stories, their life is put into their food. Hive
              offers unique experiences around food by bringing people together
              over home cooked meals. It facilitates deeper cultural exchange
              and builds communities. Through the Hive platform, people can
              discover meals for any type of social eating experience.
            </h3>
          </header>
          <section className="challenge-section">
            <span className="project-step-number">01</span>
            <h2>the challenge</h2>
            <div className="challenge">
              <div className="challenge-desc">
                <p>
                  Increasing number of people are have difficulty managing their
                  fast-paced lifestyle, diet & health. This has resulted in
                  large number of people getting out of shape. At the same time
                  the booming travel industry has exposed people to food from
                  different localities, though this food may not always be the
                  true reflection of the culture & the region.
                </p>
              </div>
              <div className="challenge-credits">
                <div className="topbar" />
                <span className="credits-text">
                  <strong>YEAR:</strong> 2017
                </span>
                <span className="credits-text">
                  <strong>DURATION:</strong> 4 months
                </span>
                <span className="credits-text">
                  <strong>PROJECT TYPE:</strong> Service-system design of food
                  experiences in local communities.
                </span>
                <span className="credits-text">
                  <strong>TEAM MEMBERS:</strong> Individual project
                </span>
                <span className="credits-text">
                  <strong>MY ROLE:</strong> Desk research, market analysis,
                  qualitative research in the form of phone interviews, analysis
                  of research, concept & functionality definition, designing the
                  overall product-service-ecosystem, business design, UX
                  strategy & service blueprinting.
                </span>
              </div>
            </div>
            <div className="image">
              <Img
                fluid={props.data.hp1challenge1.childImageSharp.fluid}
                alt="hive part - millenial effect"
                title="hive part - millenial effect"
              />
            </div>
            <p className="full-text">
              Today’s on-demand & convenience culture continues to redefine
              everyday expectations. For most people eating convenient food is
              easy, but eating convenient food that is healthy, delicious &
              authentic is difficult.
            </p>
            <p className="full-text">
              By 2020, customer experience will dominate as the key brand
              differentiator, more than price or product.’ How can brands create
              meaning and seize attention when people’s interactions are
              becoming faster and shallower? The challenge was thus to think of
              food as more than just a routine meal but as a memorable
              experience that serves as a catalyst for self improvement.
            </p>

            <div className="project-outcome">
              <a href={"#outcome-section"} className="project-outcome-link">
              see project outcomes >>
              </a>
            </div>


          </section>

          <section className="process-section">
            <span className="project-step-number">02</span>
            <h2>the approach</h2>
            <div className="process-image">
              <Img fluid={props.data.hive1_process.childImageSharp.fluid} />
            </div>
            <p className="full-text">
              Desk research, benchmarking & trend analysis was carried out to
              understand the current landscape when it comes to people, food and
              their eating habits.
            </p>
            <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.hp1process2.childImageSharp.fluid} />
              <Img fluid={props.data.hp1process3.childImageSharp.fluid} />
            </Slider>
            <div className="process">
              <div className="process-left">
                <p>
                  Next certain assumptions were verified by speaking to
                  different groups of people through phone calls and in-person
                  interviews. To make sense of all the research, the findings
                  were analysed using structured tools such as stakeholder maps,
                  empathy map, personas & customer journey map.
                </p>
              </div>
              <div className="process-right">
                <p>
                  The goal here was to understand clearly the underlying
                  problems & insights so as to develop a clear set of
                  opportunities. Through the stakeholder map i understood the
                  ecosystem i was designing for; who are the key stakeholders,
                  where they come from and the level of influence they have on
                  the main target user groups.
                </p>
              </div>
            </div>
            <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.hp1process4.childImageSharp.fluid} />
              <Img fluid={props.data.hp1process5.childImageSharp.fluid} />
              <Img fluid={props.data.hp1process6.childImageSharp.fluid} />
            </Slider>
            <p className="full-text">
              2 archetypes were then created; first were explorers who are on
              the lookout for authentic local food experiences wherever they
              travel. The second archetype focused on was a working professional
              who is looking for convenient food that is healthy. The helped me
              understand their respective behaviours, pains, mindset, feelings
              and maintain focus on their needs while developing the service.
            </p>
            <Slider className="image-scroll" {...settings}>
              <Img
                className="project-process-images"
                fluid={props.data.hp1process7.childImageSharp.fluid}
                alt=""
                title=""
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1process8.childImageSharp.fluid}
                alt=""
                title=""
              />
            </Slider>
            <p className="full-text">
              Customer journeys were mapped out for different scenarios and
              contexts the archetypes go through.
            </p>
            <Slider className="image-scroll" {...settings}>
              <Img
                className="project-process-images"
                fluid={props.data.hp1process9.childImageSharp.fluid}
                alt=""
                title=""
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1process10.childImageSharp.fluid}
                alt=""
                title=""
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1process11.childImageSharp.fluid}
                alt=""
                title=""
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1process12.childImageSharp.fluid}
                alt=""
                title=""
              />
            </Slider>
            <p className="full-text">
              This allowed me to identify the key elements of the touchpoints,
              high & low points. Insights were drawn up from which 6 oppurtunity
              areas for a new service concept were identified.
            </p>
            <div className="two-sidebyside">
              <div className="img left">
                <Img
                  className="project-process-images"
                  fluid={props.data.hp1process13.childImageSharp.fluid}
                  alt=""
                  title=""
                />
              </div>
              <div className="img right">
                <Img
                  className="project-process-images"
                  fluid={props.data.hp1process14.childImageSharp.fluid}
                  alt=""
                  title=""
                />
              </div>
            </div>
            <p className="full-text">
              It’s important to differentiate between a service and an
              experience; services as time well saved and experiences as time
              well spent. The idea was thus to design a service-experience that
              steps in to become life-long partners of the consumer by offering
              tools designed to improve their wellbeing. Whether that means
              becoming more healthy or having a once-in-a-lifetime experiences.
            </p>
            <div className="two-sidebyside">
              <div className="img left">
                <Img
                  className="project-process-images"
                  fluid={props.data.hp1process15.childImageSharp.fluid}
                  alt=""
                  title=""
                />
              </div>
              <div className="img right">
                <Img
                  className="project-process-images"
                  fluid={props.data.hp1process16.childImageSharp.fluid}
                  alt=""
                  title=""
                />
              </div>
            </div>
            <h4>THE CONCEPT</h4>
            <p className="full-text">
              Hive is a platform that connects local chefs in a given
              neighbourhood with diners who are either looking for convenient &
              healthy food options or are looking for unique food experiences.
              In its essence it brings people together, facilitates cultural
              exchange and have a good time over food.
            </p>
            <Slider className="image-scroll" {...settings}>
              <Img
                className="project-process-images"
                fluid={props.data.hp1process17.childImageSharp.fluid}
                alt=""
                title=""
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1process18.childImageSharp.fluid}
                alt=""
                title=""
              />
            </Slider>
            <p className="full-text">
              With the overall concept defined, it was time to come up with a
              strong UX strategy that would allow the Hive service-experience to
              be functional, desirable, social, sustainable and scalable.
            </p>
            <div className="image">
              <Img
                fluid={props.data.hp1process19.childImageSharp.fluid}
                alt="hive part - millenial effect"
                title="hive part - millenial effect"
              />
            </div>
            <h4>A COMMUNITY OF LOCAL CHEFS </h4>
            <p className="full-text">
              At the heart of the service are the people; the local chefs. These
              are individuals with a passion for cooking and hosting great
              experiences. The crowdsourcing of local chef was an important
              strategy that puts Hive in a great position to be sustainable and
              scalable. At the same time it ensures that there is an ever
              changing meal & experience variety with a personal touch &
              authentic taste, as every local chef is unique in their own way.
            </p>
            <Slider className="image-scroll" {...settings}>
              <Img
                className="project-process-images"
                fluid={props.data.hp1process20.childImageSharp.fluid}
                alt=""
                title=""
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1process21.childImageSharp.fluid}
                alt=""
                title=""
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1process22.childImageSharp.fluid}
                alt=""
                title=""
              />
            </Slider>
            <h4>TRUST BETWEEN GUESTS AND LOCAL CEHFS </h4>
            <p className="full-text">
              Trust is the most vital element that makes the service work,
              without it, none of the interactions will take place. It was thus
              extremely important to create conditions that fosters trust among
              the guests, local chefs and the service.
            </p>
            <Slider className="image-scroll" {...settings}>
              <Img
                className="project-process-images"
                fluid={props.data.hp1process23.childImageSharp.fluid}
                alt=""
                title=""
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1process24.childImageSharp.fluid}
                alt=""
                title=""
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1process25.childImageSharp.fluid}
                alt=""
                title=""
              />
            </Slider>
            <p className="full-text">
              In order to cook a healthy meal, one has to use good quality &
              fresh ingredients. This lead to the strategic decision to expand
              the Hive ecosystem by creating Local Hive Markets. These will give
              the local chefs easy & quick access to organic, high quality &
              fresh ingredients to cook meals that are healthy - this in turns
              builds trust among the users towards the local chefs and Hive as
              the meals are truly healthy.
            </p>
            <Slider className="image-scroll" {...settings}>
              <Img
                className="project-process-images"
                fluid={props.data.hp1process26.childImageSharp.fluid}
                alt=""
                title=""
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1process27.childImageSharp.fluid}
                alt=""
                title=""
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1process28.childImageSharp.fluid}
                alt=""
                title=""
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1process29.childImageSharp.fluid}
                alt=""
                title=""
              />
            </Slider>
            <h4>STRATEGIC PARTNERS</h4>
            <p className="full-text">
              For the service to operate efficiently and effectively it needed
              to have complete focus in delivering its core value proposition.
              This meant that other activities needed for the service to
              function had to be managed through external partners.
            </p>
            <Slider className="image-scroll" {...settings}>
              <Img
                className="project-process-images"
                fluid={props.data.hp1process30.childImageSharp.fluid}
                alt=""
                title=""
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1process31.childImageSharp.fluid}
                alt=""
                title=""
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1process32.childImageSharp.fluid}
                alt=""
                title=""
              />
            </Slider>
            <h4>INTERNET OF THINGS & DATA ANALYTICS </h4>
            <p className="full-text">
              By tapping into contextual data, Hive crafts proactive and
              predictive services that allow it to take its consumers on
              personalised journeys. Access to various sources of consumer’s
              data (with transparency & consent), together with the Hive’s
              learning algorithm & data analytics, allows the service to
              breathes life into IoT - creating a truly living service.
            </p>
            <Slider className="image-scroll" {...settings}>
              <Img
                className="project-process-images"
                fluid={props.data.hp1process33.childImageSharp.fluid}
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1process34.childImageSharp.fluid}
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1process35.childImageSharp.fluid}
              />
            </Slider>
            <h4>BEHAVIOURAL CHANGE </h4>
            <p className="full-text">
              One might create the best service & experience, but if the user
              does not change their behaviour to use the service, then it is
              useless. In the Hive service-experience there are motivational,
              ability & triggering elements that promote behavioural change.
            </p>
            <Slider className="image-scroll" {...settings}>
              <Img
                className="project-process-images"
                fluid={props.data.hp1process36.childImageSharp.fluid}
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1process37.childImageSharp.fluid}
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1process38.childImageSharp.fluid}
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1process39.childImageSharp.fluid}
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1process40.childImageSharp.fluid}
              />
            </Slider>
            <p className="full-text">
              The Hive service also includes other features such as Cravelist
              for users to add meals & experiences they would like to try in the
              near future.
            </p>
            <p className="full-text">
              Hive communities is another section of the Hive service ecosystem
              that involves organising of events, talks, masterclasses, farm
              visits and many other activities that bring together all the
              people to meet in person and build trust.
            </p>
            <Slider className="image-scroll" {...settings}>
              <Img
                className="project-process-images"
                fluid={props.data.hp1process41.childImageSharp.fluid}
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1process42.childImageSharp.fluid}
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1process43.childImageSharp.fluid}
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1process44.childImageSharp.fluid}
              />
            </Slider>
            <p className="full-text">
              A service blueprint was mapped out so as to to ensure that all the
              UX strategic elements, functionalities, the different touchpoints,
              interactions and stakeholders of the service come together to
              create a seamless experience.
            </p>
            <Slider className="image-scroll" {...settings}>
              <Img
                className="project-process-images"
                fluid={props.data.hp1process45.childImageSharp.fluid}
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1process46.childImageSharp.fluid}
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1process47.childImageSharp.fluid}
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1process48.childImageSharp.fluid}
              />
            </Slider>
            <h4>THE SERVICE BLUEPRINT </h4>
            <div className="full-text">
              The mapping of the entire experience, front-end and back-end
              started with first identifying the main scenarios the users and
              the local chef encounters.
              <ol>
                <li>Onbaording of user and local chefs on the platform.</li>
                <li>
                  Local chef listing an experience on the platform and hosting
                  an experience.
                </li>
                <li>
                  User searching, booking an experience on the platform and
                  attending an experience.
                </li>
                <li>
                  User getting presonalised & cravelist notification depending
                  on their context.
                </li>
                <li>
                  Local chef preparing to host an experience and attending an
                  Hive Market.
                </li>
              </ol>
            </div>
            <p className="full-text">
              For each of these scenarios, the respective Jobs To Be Done was
              understaood and then the user flows was mapped out. For each step
              of the user flow; the context, stakeholders involved, touchpoints
              & goals/needs were identified.
            </p>
            <Slider className="image-scroll" {...settings}>
              <Img
                className="project-process-images"
                fluid={props.data.hp1process49.childImageSharp.fluid}
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1process50.childImageSharp.fluid}
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1process51.childImageSharp.fluid}
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1process52.childImageSharp.fluid}
              />
            </Slider>
            <p className="full-text">
              A systems map and business model canvas was mapped out to identify
              costs and revenue streams in order to ensure that the service is
              feasible, sustainable and scalable in the long run.
            </p>
            <div className="two-sidebyside">
              <div className="img left">
                <Img
                  className="project-process-images"
                  fluid={props.data.hp1process53.childImageSharp.fluid}
                  alt=""
                  title=""
                />
              </div>
              <div className="img right">
                <Img
                  className="project-process-images"
                  fluid={props.data.hp1process54.childImageSharp.fluid}
                  alt=""
                  title=""
                />
              </div>
            </div>
          </section>
          
          <section id="outcome-section" className="outcome-section">
            <div className="outcome-text-wrapper">
              <span className="project-step-number">03</span>
              <h2>the outcome</h2>
              <h3>
                Hive is for everyone; whether you are exploring your town or
                traveling to a new city, Hive is an easy way to taste healthy
                delicious food while meeting & connecting with interesting
                people. With Hive one can learn about a country’s history and
                culture through engaging with local people and discovering its
                typical food.
              </h3>
              <div className="outcome">
                <div className="outcome-left">
                  <p>
                    How about a cheese and wine tasting in a beautiful Parisian
                    home, learning how to make homemade pasta with a local
                    Roman, a local supper club in a magical garden in London, or
                    enjoying a feast of tapas with a local on a Barcelona
                    rooftop? Hive ensures that you’ll find the perfect food
                    experience.
                  </p>
                  <p>
                    Hive offers a truly farm-to-table experience as it is
                    designed with the aim of providing an end-to-end service
                    ecosystem. Local Hive Markets offers farmers the platform to
                    sell their fresh produce to local chefs. By facilitating
                    easy access to local & high quality ingredients to cook
                    their meals from, Hive ensures that the meals users enjoy on
                    their plate are truly healthy and delicious.
                  </p>
                </div>
                <div className="outcome-right">
                  <p>
                    Hive goes one step further through its Hive collections.
                    These are holistic Hive experience that first make sure that
                    all the meals are healthy, authentic and delicious tasting.
                    It then completes the experience by guiding users to enjoy
                    the food at perfect locations with the right ambience,
                    style, feel and comfort.
                  </p>
                  <p>
                    Hive moves away from basic transactions to offer compelling
                    & memorable experiences. It taps into contextual
                    data/attributes, machine learning algorithms & AI to craft
                    higher levels of personalised service such as
                    recommendations on food experiences that are tailored to the
                    transient needs of the user.
                  </p>
                </div>
              </div>
              <h3>
                Digital disruption does not call for a retreat from all physical
                infrastructure. Hive strikes a balance between human, physical
                and digital experience woven together seamlessly across the
                online and offline world.
              </h3>
            </div>
            <Slider className="image-scroll" {...settings}>
              <Img
                className="project-process-images"
                fluid={props.data.hp1outcome55.childImageSharp.fluid}
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1outcome56.childImageSharp.fluid}
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1outcome57.childImageSharp.fluid}
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1outcome58.childImageSharp.fluid}
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1outcome59.childImageSharp.fluid}
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1outcome60.childImageSharp.fluid}
              />

              <Img
                className="project-process-images"
                fluid={props.data.hp1outcome61.childImageSharp.fluid}
              />
            </Slider>
            <p className="center-text black">
              To see the design of the Hive website & mobile app, check out the
              project;
            </p>
            <h2 className="next-title">
              <a href="/work/service-design-hive-part-2">
                Hive (part 2) - Design of digital touchpoints
              </a>
            </h2>
          </section>

          <section id="footertopcta" className="inner">
            <p className="text arrow-back">
              head back to check out my
              <br />
              <Link to="/#one">featured work.</Link>
            </p>
          </section>
        </div>
      </div>
    </div>
    <div className="footer-top">
      <div className="inner">
        <h2>other projects.</h2>
        <div id="work">
          <div className="card-wrapper">
            <a href="/work/service-design-mercedes">
              <div className="image">
                <Img fluid={props.data.project1.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">electric car charging service.</div>
                <div className="text">
                  A seamless parking & charging solution for Mercedes Benz &
                  Smart Electric Car owners living in urban areas.
                </div>
                <div className="tags">
                  <ul>
                    <li>SERVICE DESIGN</li>
                    <li>USER EXPERIENCE</li>
                    <li>DIGITAL UX/UI</li>
                    <li>INTERACTION DESIGN</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
          <div className="card-wrapper">
            <a href="/work/service-design-muvisi-private">
              <div className="image">
                <Img fluid={props.data.project2.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">muvisi</div>
                <div className="text">
                  A mobility service that makes it stress-free & convenient to
                  find parking spots in the city.
                </div>
                <div className="tags">
                  <ul>
                    <li>SERVICE DESIGN</li>
                    <li>USER EXPERIENCE</li>
                    <li>DIGITAL UX/UI</li>
                    <li>INTERACTION DESIGN</li>
                    <li>BRANDING</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ServiceDesignHP1

export const fluidHP1Image = graphql`
  fragment fluidHP1Image on File {
    childImageSharp {
      fluid(maxWidth: 920, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    hp1cover: file(
      relativePath: { eq: "projects/service/hive/cover/hive-cover-image.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1challenge1: file(
      relativePath: { eq: "projects/service/hive/challenge/1.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process2: file(
      relativePath: { eq: "projects/service/hive/process/2.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process3: file(
      relativePath: { eq: "projects/service/hive/process/3.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process4: file(
      relativePath: { eq: "projects/service/hive/process/4.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process5: file(
      relativePath: { eq: "projects/service/hive/process/5.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process6: file(
      relativePath: { eq: "projects/service/hive/process/6.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process7: file(
      relativePath: { eq: "projects/service/hive/process/7.png" }
    ) {
      ...fluidHP1Image
    }
    hp1process8: file(
      relativePath: { eq: "projects/service/hive/process/8.png" }
    ) {
      ...fluidHP1Image
    }
    hp1process9: file(
      relativePath: { eq: "projects/service/hive/process/9.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process10: file(
      relativePath: { eq: "projects/service/hive/process/10.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process11: file(
      relativePath: { eq: "projects/service/hive/process/11.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process12: file(
      relativePath: { eq: "projects/service/hive/process/12.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process13: file(
      relativePath: { eq: "projects/service/hive/process/13.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process14: file(
      relativePath: { eq: "projects/service/hive/process/14.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process15: file(
      relativePath: { eq: "projects/service/hive/process/15.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process16: file(
      relativePath: { eq: "projects/service/hive/process/16.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process17: file(
      relativePath: { eq: "projects/service/hive/process/17.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process18: file(
      relativePath: { eq: "projects/service/hive/process/18.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process19: file(
      relativePath: { eq: "projects/service/hive/process/19.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process20: file(
      relativePath: { eq: "projects/service/hive/process/20.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process21: file(
      relativePath: { eq: "projects/service/hive/process/21.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process22: file(
      relativePath: { eq: "projects/service/hive/process/22.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process23: file(
      relativePath: { eq: "projects/service/hive/process/23.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process24: file(
      relativePath: { eq: "projects/service/hive/process/24.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process25: file(
      relativePath: { eq: "projects/service/hive/process/25.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process26: file(
      relativePath: { eq: "projects/service/hive/process/26.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process27: file(
      relativePath: { eq: "projects/service/hive/process/27.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process28: file(
      relativePath: { eq: "projects/service/hive/process/28.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process29: file(
      relativePath: { eq: "projects/service/hive/process/29.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process30: file(
      relativePath: { eq: "projects/service/hive/process/30.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process31: file(
      relativePath: { eq: "projects/service/hive/process/31.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process32: file(
      relativePath: { eq: "projects/service/hive/process/32.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process33: file(
      relativePath: { eq: "projects/service/hive/process/33.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process34: file(
      relativePath: { eq: "projects/service/hive/process/34.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process35: file(
      relativePath: { eq: "projects/service/hive/process/35.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process36: file(
      relativePath: { eq: "projects/service/hive/process/36.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process37: file(
      relativePath: { eq: "projects/service/hive/process/37.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process38: file(
      relativePath: { eq: "projects/service/hive/process/38.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process39: file(
      relativePath: { eq: "projects/service/hive/process/39.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process40: file(
      relativePath: { eq: "projects/service/hive/process/40.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process41: file(
      relativePath: { eq: "projects/service/hive/process/41.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process42: file(
      relativePath: { eq: "projects/service/hive/process/42.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process43: file(
      relativePath: { eq: "projects/service/hive/process/43.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process44: file(
      relativePath: { eq: "projects/service/hive/process/44.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process45: file(
      relativePath: { eq: "projects/service/hive/process/45.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process46: file(
      relativePath: { eq: "projects/service/hive/process/46.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process47: file(
      relativePath: { eq: "projects/service/hive/process/47.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process48: file(
      relativePath: { eq: "projects/service/hive/process/48.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process49: file(
      relativePath: { eq: "projects/service/hive/process/49.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process50: file(
      relativePath: { eq: "projects/service/hive/process/50.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process51: file(
      relativePath: { eq: "projects/service/hive/process/51.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process52: file(
      relativePath: { eq: "projects/service/hive/process/52.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process53: file(
      relativePath: { eq: "projects/service/hive/process/53.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1process54: file(
      relativePath: { eq: "projects/service/hive/process/54.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1outcome61: file(
      relativePath: { eq: "projects/service/hive/outcome/61.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1outcome60: file(
      relativePath: { eq: "projects/service/hive/outcome/60.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1outcome59: file(
      relativePath: { eq: "projects/service/hive/outcome/59.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1outcome58: file(
      relativePath: { eq: "projects/service/hive/outcome/58.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1outcome57: file(
      relativePath: { eq: "projects/service/hive/outcome/57.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1outcome56: file(
      relativePath: { eq: "projects/service/hive/outcome/56.jpg" }
    ) {
      ...fluidHP1Image
    }
    hp1outcome55: file(
      relativePath: { eq: "projects/service/hive/outcome/55.jpg" }
    ) {
      ...fluidHP1Image
    }
    hive1_process: file(
      relativePath: { eq: "projects/process/hive1-process.png" }
    ) {
      ...fluidHP1Image
    }
    project1: file(relativePath: { eq: "projects/3.png" }) {
      ...fluidHP1Image
    }
    project2: file(relativePath: { eq: "projects/19.jpg" }) {
      ...fluidHP1Image
    }
  }
`
